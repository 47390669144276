import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div id="header">
      <div id='left-header'>
        <Link to='/'>PostsFeed</Link>
      </div>
      <div id='right-header'>
        <Link to='/create'>Create a post</Link>
      </div>
    </div>
  )
}

export default Header
