import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'

import Header from './components/Header'
import MainPage from './components/MainPage'
import CreatePage from './components/CreatePage'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" exact element={<MainPage />} />
        <Route path="/create" exact element={<CreatePage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
