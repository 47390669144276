import React, { useState } from 'react'

function CreatePage() {
  return (
    <div id="form-container">
      <Instruction />
      <Form />
    </div>
  )
}

function Instruction() {
  return (
    <>
      <h2>You can add another post to the list here.</h2>
      <ul>
        <li>
          You cannot make a post with the same title as another post you made,
          but different usernames can use the same post.
        </li>
      </ul>
    </>
  )
}

function Form() {
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const handleContentChange = (event) => {
    setContent(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (username === '') {
      alert('Username field should not be empty')
    } else if (title === '') {
      alert('Title field should not be empty')
    } else if (title === '') {
      alert('Content field should not be empty')
    } else {
      const res = await fetch('https://posts.aaryanprakash.workers.dev/posts', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ username, title, content }),
      })

      const message = await res.text()
      alert(message)
    }
  }

  return (
    <form id="input-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label for="username">Username:</label>
        <input type="text" name="username" onChange={handleUsernameChange} />
      </div>

      <div className="form-group">
        <label for="title">Title:</label>
        <input type="text" name="title" onChange={handleTitleChange} />
      </div>

      <div className="form-group">
        <label for="content">Content:</label>
        <textarea
          type="text"
          name="content"
          rows="6"
          onChange={handleContentChange}
        />
      </div>

      <input type="submit" />
    </form>
  )
}

export default CreatePage
