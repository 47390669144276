import React, { useState, useEffect } from 'react'

const postsURL = 'https://posts.aaryanprakash.workers.dev/posts'

export function MainPage() {
  const [pagesData, setPagesData] = useState([])

  // only run when document is created
  useEffect(() => {
    async function fetchData() {
      const data = await getPosts()
      setPagesData(data)
    }

    fetchData()
  }, [])

  const postsComponents = pagesData.map((post) => Post(post))
  return <div className="posts-container">{postsComponents}</div>
}

function Post(postData) {
  return (
    <div className="post-box">
      <div className="post-title">
        {postData.title} by {postData.username}
      </div>
      <p className="post-content">{postData.content}</p>
    </div>
  )
}

async function getPosts() {
  return await fetch(postsURL).then((res) => res.json())
}

export default MainPage
